<template>
  <div id="cost-center-table">
    <main class="centro-de-custo">
      <div class="subtitle">
        <p>Centro de custo</p>
        <HelpCircle class="icon" id="subtitle-tooltip-centro-de-custo" />
      </div>

      <b-tooltip
        target="subtitle-tooltip-centro-de-custo"
        triggers="hover"
        placement="bottom"
      >
        <p class="order-body">
          Departamento ou unidade de negócio para identificação dos custos
          separados por centros de custo.
        </p>
      </b-tooltip>

      <div class="outages">
        <b-row>
          <b-col cols="4">
            <div class="form-group">
              <div class="order-body">
                <p>Centro de custo padrão para contas a pagar</p>
                <multiselect
                  v-model="defaultCostCenter"
                  :options="defaultCostCenterOptions"
                  track-by="value"
                  label="label"
                  :showLabels="false"
                  :searchable="true"
                  :allowEmpty="false"
                  placeholder="Selecione"
                  class="with-border"
                  @input="updateDefaultCostCenter"
                  :disabled="!canEdit"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>

                  <template slot="noOptions"> Nenhuma opção </template>

                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
              </div>
            </div>
          </b-col>
        </b-row>

        <table>
          <thead class="data-head">
            <tr>
              <th class="head-cell">
                <p>Nome</p>
              </th>
              <th class="head-cell">
                <p>Descrição</p>
              </th>
              <th class="head-cell"></th>
            </tr>
          </thead>

          <tbody class="data-body">
            <tr class="items">
              <div class="item" v-for="center in costCenters" :key="center.id">
                <td class="cell">
                  <p>{{ center.name }}</p>
                </td>

                <td class="cell reason">
                  <p>{{ center.description }}</p>
                </td>

                <td class="cell center-options margin-left-auto">
                  <div class="receive d-flex">
                    <div class="optionsDiv">
                      <p class="status" v-if="center.active">Ativo</p>
                      <p class="status" v-else>Inativo</p>
                    </div>

                    <div class="optionsDiv">
                      <toggle-button
                        :sync="true"
                        :height="32"
                        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                        v-model="center.active"
                        @input="
                          updateActiveCostCenter(center.id, center.active)
                        "
                        :disabled="!userHasPermission('FiConCoBan3')"
                      />

                      <div class="close">
                        <Close @click="getCostCenterDefaultForm" />
                      </div>
                    </div>
                    <div
                      v-if="
                        userHasPermission('FiConCoBan4') ||
                        userHasPermission('FiConCoBan5')
                      "
                      class="actions"
                    >
                      <Icon tooltip="Editar custo">
                        <Edit
                          class="icon"
                          @click="
                            editCostCenter(
                              center.id,
                              center.created_at,
                              center.name,
                              center.description,
                              center.active
                            )
                          "
                        />
                      </Icon>
                      <Icon tooltip="Remover custo">
                        <Delete class="icon" @click="openDeleteModal(center)" />
                      </Icon>
                    </div>
                  </div>
                </td>
              </div>
            </tr>

            <tr class="items">
              <div class="create" v-if="createCostCenter">
                <td class="cell">
                  <div class="input">
                    <b-form-input
                      id="name"
                      autocomplete="off"
                      placeholder="Nome"
                      v-model="costCenterForm.name"
                    >
                    </b-form-input>
                  </div>
                </td>

                <td class="cell">
                  <div class="input">
                    <b-form-input
                      id="description"
                      placeholder="Descrição"
                      autocomplete="off"
                      v-model="costCenterForm.description"
                    ></b-form-input>
                  </div>
                </td>

                <td class="cell center-options status-toggle-create">
                  <div class="receive d-flex">
                    <div class="optionsDiv">
                      <p v-if="costCenterForm.active">Ativo</p>
                      <p v-else>Inativo</p>
                    </div>

                    <div class="optionsDiv">
                      <toggle-button
                        :sync="true"
                        :height="32"
                        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                        v-model="costCenterForm.active"
                      />
                    </div>

                    <div class="optionsDiv">
                      <Close width="24" @click="getCostCenterDefaultForm" />
                    </div>
                  </div>
                </td>
              </div>

              <div class="validate">
                <td></td>
                <td>
                  <div
                    v-if="validated && !costCenterForm.name"
                    class="custom-invalid-feedback"
                  >
                    Campo obrigatório
                  </div>
                </td>
                <td>
                  <div
                    v-if="validated && !costCenterForm.description"
                    class="custom-invalid-feedback"
                  >
                    Campo obrigatório
                  </div>
                </td>
                <td></td>
                <td></td>
              </div>
            </tr>

            <tr class="items">
              <div class="create" v-if="editCostCenterForm">
                <td class="cell">
                  <div class="input">
                    <b-form-input
                      autocomplete="off"
                      ref="costCenterNameInput"
                      v-model="costCenterForm.name"
                      id="name"
                    ></b-form-input>
                    <div
                      v-if="validated && !costCenterForm.name"
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>
                </td>

                <td class="cell">
                  <div class="input">
                    <b-form-input
                      autocomplete="off"
                      v-model="costCenterForm.description"
                      id="description"
                    >
                    </b-form-input>
                    <div
                      v-if="validated && !costCenterForm.description"
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>
                </td>

                <td class="cell center-options">
                  <div class="receive d-flex">
                    <div class="optionsDiv">
                      <p class="status" v-if="costCenterForm.active">Ativo</p>
                      <p class="status" v-else>Inativo</p>
                    </div>
                    <div class="optionsDiv">
                      <toggle-button
                        :sync="true"
                        :height="32"
                        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                        v-model="costCenterForm.active"
                        @input="null"
                      />
                    </div>

                    <div class="optionsDiv">
                      <Close width="20" @click="getCostCenterDefaultForm" />
                    </div>
                  </div>
                </td>
              </div>

              <div class="add-btn">
                <button
                  v-if="!createCostCenter && !editCostCenterForm"
                  @click="createCostCenter = true"
                >
                  Adicionar
                </button>

                <button v-else class="saveBtn" @click="saveCostCenter">
                  Salvar
                </button>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
    <b-modal
      id="modal-delete"
      title="Excluir centro de custo"
      @hide="getCostCenterDefaultForm()"
    >
      <p class="my-4">
        Você tem certeza que deseja excluir
        <strong>{{ costCenterForm.name }}</strong
        >?
      </p>
      <p class="my-4">
        Este centro de custo será excluído de todas as contas a pagar que
        estiverem relacionadas
      </p>
      <div slot="modal-footer" class="w-100">
        <b-btn
          size="sm"
          class="float-right delete-btn"
          variant="outline-danger"
          @click="deleteCostCenter(costCenterForm.id)"
        >
          Excluir
        </b-btn>
        <b-btn
          size="sm"
          class="float-right"
          variant="primary"
          @click="() => this.$bvModal.hide('modal-delete')"
        >
          Cancelar
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  userHasPermission,
  getCurrentClinic
} from '@/utils/localStorageManager'
import moment from 'moment'

export default {
  name: 'cost-center-table',

  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Icon: () => import('@/components/General/Icon'),
    Delete: () => import('@/assets/icons/delete.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg')
  },

  created() {
    this.getCostCenters()
  },

  data() {
    const clinic = getCurrentClinic()

    return {
      canEdit: userHasPermission('FiConCoBan4'),
      validated: false,
      defaultCostCenter: null,
      clinic_id: clinic.id,
      createCostCenter: false,
      editCostCenterForm: false,
      costCenters: [],
      defaultCostCenterOptions: [],
      costCenterForm: {
        id: null,
        createdAt: moment().format('DD/MM/YYYY'),
        name: null,
        description: null,
        active: true
      },

      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      }
    }
  },

  methods: {
    userHasPermission,

    isValidForm() {
      this.validated = true
      let isValid = true

      if (!this.costCenterForm.name || !this.costCenterForm.description)
        isValid = false

      return isValid
    },

    openDeleteModal(center) {
      this.createCostCenter = false
      this.editCostCenterForm = false
      this.costCenterForm = center
      this.$bvModal.show('modal-delete')
    },

    getCostCenters() {
      const isLoading = this.$loading.show()

      this.api
        .getCostCenters(this.clinic_id)
        .then(res => {
          this.defaultCostCenterOptions = []
          this.defaultCostCenter = null
          this.costCenters = res.data

          this.costCenters.map(center => {
            if (center.active) {
              center.active = true
              this.defaultCostCenterOptions.push({
                value: center.id,
                label: center.name
              })

              if (center.is_default) {
                this.defaultCostCenter = {
                  value: center.id,
                  label: center.name
                }
              }
            } else {
              center.active = false
            }
          })
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          isLoading.hide()
        })
    },

    async saveCostCenter() {
      if (!this.isValidForm()) return

      const isLoading = this.$loading.show()
      const data = {
        clinic_id: this.clinic_id,
        name: this.costCenterForm.name,
        description: this.costCenterForm.description,
        active: this.costCenterForm.active
      }

      if (this.costCenterForm.id) {
        await this.api
          .updateCostCenter(this.costCenterForm.id, data)
          .then(res => {
            this.getCostCenters()
            this.$toast.success('Centro de custo atualizado com sucesso!')
          })
          .catch(err => this.$toast.error(err.message))
          .finally(() => {
            isLoading.hide()
          })
      } else {
        await this.api
          .createCostCenter(data)
          .then(res => {
            this.getCostCenters()
            this.$toast.success('Centro de custo cadastrado com sucesso!')
          })
          .catch(err => this.$toast.error(err.message))
          .finally(() => {
            isLoading.hide()
          })
      }

      this.getCostCenterDefaultForm()
      this.validated = false
    },

    editCostCenter(id, createdAt, name, description, active) {
      this.costCenterForm.id = id
      this.costCenterForm.createdAt = createdAt
      this.costCenterForm.name = name
      this.costCenterForm.description = description
      this.costCenterForm.active = active
      this.editCostCenterForm = true
      setTimeout(() => {
        this.$refs.costCenterNameInput.focus()
      }, 300)
    },

    updateActiveCostCenter(id, value) {
      const isLoading = this.$loading.show()

      this.api
        .updateCostCenter(id, { active: value })
        .then(res => {
          this.getCostCenters()
          this.$toast.success('Centro de custo atualizado com sucesso!')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },

    deleteCostCenter(id) {
      const isLoading = this.$loading.show()

      this.api
        .deleteCostCenter(id)
        .then(res => {
          this.getCostCenters()
          this.$toast.success('Centro de custo removido com sucesso!')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
          this.$bvModal.hide('modal-delete')
          this.getCostCenterDefaultForm()
        })
    },

    getCostCenterDefaultForm() {
      this.costCenterForm = {
        id: null,
        name: null,
        description: null,
        active: true
      }

      this.createCostCenter = false
      this.editCostCenterForm = false
    },

    updateDefaultCostCenter(costCenter) {
      const isLoading = this.$loading.show()
      this.api
        .setDefaultCostCenter(costCenter.value)
        .then(res => {
          this.getCostCenters()
          this.$toast.success('Centro de custo padrão atualizado com sucesso!')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/ax-datepicker.scss';

#cost-center-table {
  .centro-de-custo {
    padding: 40px 10px 0px 10px;
    width: 100%;

    .doctor-selected-title {
      font-family: 'Red Hat Display';
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 30px;
      color: var(--type-active);
    }

    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 24px;
      color: var(--dark-blue);
      margin-bottom: 27px;
    }

    .subtitle {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: var(--type-active);
      padding-bottom: 24px;
      text-align: left;
      flex-direction: row;
      gap: 8px;

      .icon {
        height: 24px;
        width: 24px;
        stroke: var(--neutral-500);
      }
    }

    .outages {
      /* border: 1px solid #D9DFF2; */
      box-sizing: border-box;
      border-radius: 8px;
      /* padding: 24px; */

      .order-body {
        border-radius: 8px;

        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: var(--dark-blue);
          text-align: left;
        }
      }

      .add-btn {
        text-align: center;
        padding: 36px 0;
        border-left: 1px solid var(--neutral-200);
        border-right: 1px solid var(--neutral-200);
        border-bottom: 1px solid var(--neutral-200);
        border-radius: 0 0 7px 7px;

        button {
          transition: 0.3s;
          border: 2px solid var(--blue-500);
          padding: 8px 68px;
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: var(--blue-500);
          border-radius: 8px;

          &.saveBtn {
            color: var(--blue-500);
            border: 2px solid var(--blue-500);
          }

          &.cancel {
            color: var(--light-orange-200);
            border: 2px solid #ffdccc;
          }
        }

        button:hover {
          transition: 0.4s;
          color: white;
          background-color: var(--blue-500);
        }
      }

      .head {
        height: 70px;
        padding: 0px 20px;
        justify-content: space-around;
        align-items: center;
        color: var(--type-active);

        .title {
          margin: 0px;
          font-family: Red Hat Display;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01em;
        }

        .date {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;

          svg {
            width: 35px;
            height: 25px;
            stroke: #305bf2;
            cursor: pointer;
          }
        }
      }

      table {
        table-layout: fixed !important;
        width: 100% !important;
        border-collapse: collapse !important;
      }

      .head-cell {
        text-align: left !important;

        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          align-items: center;
          color: var(--type-active);
          text-align: left;
        }
      }

      .cell {
        white-space: normal;
        display: flex;
        align-items: left;
        flex-direction: column;

        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          align-items: center;
          color: var(--type-active);
          text-align: left;
        }
      }

      .flexComponent {
        display: flex;
      }

      .flexRow {
        flex-direction: row;
        justify-content: space-around;
      }

      .status-toggle-create {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .center-options .optionsDiv {
        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: right;
          color: var(--type-active);
        }

        svg {
          fill: var(--neutral-500);
        }
      }

      .value-tag {
        max-width: 70%;
        padding: 8px 16px;
        border-radius: 50px;
        background-color: var(--neutral-200);
        color: #000;
        text-align: center !important;
        overflow: hidden;

        &.active {
          background-color: var(--light-orange-200);
        }

        &.negative {
          background-color: #fedad7;
        }
      }

      .data-head {
        tr {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          padding: 12px 24px;
          background: var(--neutral-100);
          color: #525c7a;
          flex-direction: row;
          border-radius: 7px 7px 0px 0px !important;
          border: 1px solid var(--neutral-200);
        }
      }

      .data-body {
        .items {
          display: flex;
          flex-direction: column;
          position: relative;

          .item {
            list-style: none;
            padding: 24px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            border-bottom: 1px solid var(--neutral-200);
            border-left: 1px solid var(--neutral-200);
            border-right: 1px solid var(--neutral-200);

            .times-tag {
              margin: 0 auto;
              border-radius: 50px;
              background-color: var(--neutral-200);
              color: #000;
              text-align: center;

              &.active {
                background: var(--light-orange-200);
              }

              &.negative {
                background: #fedad7;
              }
            }

            .receive {
              display: flex;
              flex-direction: row;
              align-items: center;
              border: none !important;
              text-align: left;
              align-items: center;
              gap: 8px;

              .options {
                cursor: pointer;
                width: 24px;
                height: 28px;
                position: relative;
                display: inline-block;

                .more-icon {
                  width: 24px;
                  height: 24px;
                  stroke: var(--neutral-500);
                  transition: all 0.5s;
                  cursor: pointer;
                }

                .menu {
                  width: 160px;
                  position: absolute;
                  top: 28px;
                  right: 0;
                  background: var(--neutral-000);
                  border: 1px solid var(--neutral-200);
                  box-sizing: border-box;
                  box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
                    0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                  border-radius: 8px;
                  padding: 24px;
                  opacity: 0;
                  transition: all 0.5s;
                  flex-direction: column;
                  align-items: start;
                  display: none;

                  .mtb-12 {
                    margin: 12px 0;
                  }

                  .btn {
                    font-weight: 600;
                    margin-bottom: 16px;

                    &.remove {
                      color: var(--states-error);
                    }
                  }
                }

                &:hover {
                  .more-icon {
                    transform: rotate(90deg);
                  }

                  .menu {
                    opacity: 1;
                    display: flex;
                    z-index: 10;
                  }
                }
              }
            }
          }

          .create {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            list-style: none;
            padding: 24px;
            border-left: 1px solid var(--neutral-200);
            border-right: 1px solid var(--neutral-200);
            border-bottom: 1px solid var(--neutral-200);
            gap: 16px;

            .date-cell {
              max-height: 48px !important;
              text-align: left;
            }

            .bankEditCell {
              text-align: left;
            }

            .date-inputs {
              .mx-datepicker {
                width: unset !important;
              }
            }

            .reason {
              .multiselect {
                border: 1px solid var(--neutral-300);
                box-sizing: border-box;
                border-radius: 8px;

                .multiselect__tags {
                  padding-right: 0px;
                }

                .multiselect__single {
                  font-family: Nunito Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 150%;
                  color: var(--type-active);
                }
              }
            }

            .multiselect {
              border: 1px solid var(--neutral-300);
              box-sizing: border-box;
              border-radius: 8px;

              .multiselect__tags {
                padding-right: 0px;
              }

              .multiselect__single {
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                color: var(--type-active);
              }
            }

            .custom-invalid-feedback {
              text-align: left;
            }

            .receive {
              display: flex !important;
              flex-direction: row;
              gap: 8px;
              align-items: center;
              border: none !important;

              .status {
                height: 20px;
                margin-top: 4px;
              }

              .multiselect {
                border: 1px solid var(--neutral-300);
                box-sizing: border-box;
                border-radius: 8px;

                .multiselect__single {
                  font-family: Nunito Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 150%;
                  color: var(--type-active);
                }
              }

              .close {
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }

          .validate {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            list-style: none;
            gap: 16px;
            padding: 0 24px;
            position: absolute;
            width: 100%;
            bottom: 4px;

            td {
              text-align: left;
              padding: 0 4px;
            }
          }
        }
      }
    }
  }

  .margin-left-auto {
    margin-left: auto;
  }
}
.delete-btn {
  height: 37px !important;
  font-weight: 800 !important;
  margin-left: 20px;
  border: none !important;
  border-radius: 8px !important;
}
.actions {
  justify-content: right;
  display: flex;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    cursor: pointer;
  }
}
</style>
